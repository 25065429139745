document.addEventListener("DOMContentLoaded", function(e) {
    "use strict";

    const track = (funnelId, pageId, variationId) => {

        const payload = {
            fid: funnelId,
            pid: pageId,
            vid: variationId,
            referer: document.referrer,
            currentUrl: window.location.href,
            _wpnonce: window.OPFunnelStats.nonce
        };

        fetch(window.OPFunnelStats.rest_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (!response.ok) {
                    return;
                }
                return response;
            })
            .then((response) => response.json())
            .then((result) => {
                window.OPFunnelStats.queried_object_id = result.data.queried_object_id;
                window.OPFunnelStats.pageview_record_id = result.data.pageview_record_id;
            })
            .catch((error) => {
                console.error(error);
                return;
            });
    }

    if (window.OPFunnelStats && window.OPFunnelStats.fid && window.OPFunnelStats.pid && window.OPFunnelStats.pid) {
        track(window.OPFunnelStats.fid, window.OPFunnelStats.pid, window.OPFunnelStats.vid);
    }
});